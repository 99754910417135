import axios from "axios"

export const isBrowser = () => typeof window !== "undefined"

export const post = async (url, postData, callback = null) => {

  try {
    const res = await axios.post(url, postData,
      {
        headers: { 'X-API-Key': process.env.X_API_KEY }
      });

    if (res.data.statusCode === 200) {
      if (callback !== null) {
        callback(res);
      }
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}